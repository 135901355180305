import { AxiosError } from 'axios';
import ApiError, { ApiErrorObject } from '../errors/api-error';
import { apiErrors } from '../consts/api-errors';

export function isAxiosError(error: Error): error is AxiosError {
  return !!(error as AxiosError).isAxiosError;
}

interface AxiosApiError extends AxiosError<ApiErrorObject> {
  response: NonNullable<AxiosError<ApiErrorObject>['response']>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosApiError(error: any): error is AxiosApiError {
  return isAxiosError(error) && error.response && error.response.data && ApiError.isApiErrorObject(error.response.data);
}

export function getErrorMessage(error: Error): string {
  if (isAxiosApiError(error)) {
    return apiErrors[error.response.data.code];
  }
  if (isAxiosError(error) && error.response && error.response.data) {
    if (error.response.data.message) {
      return error.response.data.message;
    }
  }
  return error.message;
}

export function serializeAxiosError(error: AxiosError) {
  const { config, response } = error;
  return {
    request: {
      url: config.url,
      method: config.method,
      data: config.data,
    },
    response: response && {
      status: response.status,
      data: response.data,
    },
  };
}
